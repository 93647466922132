import DynamicComponent from "components/DynamicComponent"

export default function ColoredBackgroundSection({ blok }) {
  return (
    <section className="bg-springWood">
      {blok?.title ? <h2 className="pt-8 text-center text-teal-dark">{blok.title}</h2> : null}
      {blok?.body?.length ? blok.body.map(({ _uid, ...blok }) => <DynamicComponent blok={blok} key={_uid} />) : null}
    </section>
  )
}
